export const get = async (url, headers) => {
    return await fetch(url,
        {
                method: "GET",
                headers: headers,
                
        })
        .then((response) => response.json())
        .then((result) => {
                return result;
        })
        .catch((error) => {
                return { 'status': 0, 'message': 'Something went wrong, ERROR: ' + error }

        })
}


export const post = async (url, body, headers) => {
    return await fetch(url,
        {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
              
        })
        .then((response) => response.json())
        .then((result) => {
                return result;
        })
        .catch((error) => {
                return { 'status': 0, 'message': 'Something went wrong, ERROR: ' + error }

        })
}


export const patch = async (url, body, headers) => {
    return  await fetch(url,
        {
                method: "PATCH",
                headers: headers,
                body: JSON.stringify(body),
           
        })
        .then((response) => response.json())
        .then((result) => {
                return result;
        })
        .catch((error) => {
                return { 'status': 0, 'message': 'Something went wrong, ERROR: ' + error }

        })
}

// module.exports = {
//         get:get,
//         post:post,
//         patch:patch
// }