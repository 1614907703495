// import cogoToast from 'cogo-toast';
import toast from 'react-hot-toast';

export const toasted = {
        success: async function (message) {
                toast.success(message);
        },
        error: async function (message) {
                toast.error(message)

        }

      
              
}
// toast.promise(
//          {
//            loading: 'Preparing...',
//            success: <b>Done!</b>,
//            error: <b>Could not find.</b>,
//          }
//        );
// module.exports = { success }
// export default success;
export default toasted;
