import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { API_URL } from "../../config/Config";
import Spinner from "../Spinner";
import { useNavigate, Link } from "react-router-dom";
import { get, post } from "../../config/apis";
import dateFormat from "dateformat";
import { Sparklines, SparklinesLine } from 'react-sparklines';
// import ModalImage from "react-modal-image";
import toasted from '../../config/toast';
// import { FcBearish, FcBullish } from "react-icons/fc";  


function MainMarket() {
  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [user, setUser] = useState('');
  const [records, setrecords] = useState(0);
  const [search, setSearch] = useState('')
  const [value, setValue] = useState('')
  const [sorting, setSorting] = useState({ key: '', ascending: false })
  let [p, setP] = useState(' ')

  const handleFetch = async () => {
    setUser(null)
    setLoading(true);
    var passcon= '';
    if(value !== '' && value !== undefined) {
       passcon = `&search=symbol&value=${value?.toLocaleLowerCase()}`;
    }
    const res = await get(`${API_URL}/market/marketDetails?sort=${sorting.key}&page=${currentPage}&limit=${perPage}&market_status=true${passcon}`,
      {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("Token"),
      }
    );
    if (res?.status === "success") {
      setUser(res);
      setrecords(res.total);
      setLoading(false);
      setP(res.page + 1)
    } else if (res?.status === "error") {
      localStorage.clear();
      navigate("/login");
    }
  }
  const handlePageChange = async (e) => {
    const newOffset = (e.selected + 1);
    setcurrentPage(newOffset);
  };

  useEffect(() => {
    handleFetch(); // eslint-disable-next-line
  }, [currentPage, perPage, search, value]);

  const submitForm = async (e) => {
    e.preventDefault();
    handleFetch();
  }
  const activateMarket = async (e) => {
    e.preventDefault();
    const id = e.target[0].value;
    const res = await post(`${API_URL}/market/activateMarket`, { id }, {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("Token")
    })
    if (res?.status === "success") {
      toasted.success(res.message)
      handleFetch();
    } else {
      toasted.error(res.message)
    }

  }
  const deactivateMarket = async (e) => {
    e.preventDefault();
    const id = e.target[0].value;
    const res = await post(`${API_URL}/market/deActivateMarket`, { id }, {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("Token")
    })
    if (res?.status === "success") {
      toasted.success(res.message)
      handleFetch();
    } else {
      toasted.error(res.message)
    }
  }

  const applySorting = (key, ascending) => {
    if (ascending) {
      setSorting({ key: '', ascending: false });
      setSorting({ key: '-' + key, ascending: ascending });
      handleFetch();
    } else {
      setSorting({ key: '', ascending: false });
      setSorting({ key: key, ascending: ascending });
      handleFetch();

    }

  }


  const handleChange = async (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
  }


  return (
    <div className="content-wrapper-new">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"> <h1>MAIN MARKET</h1> </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                <li className="breadcrumb-item active">Main Market</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="card card-primary card-outline card-outline-tabs mb-11">
        <div className="card-body p-0">
          <div className="tab-content" id="custom-tabs-four-tabContent">
            <div className="tab-pane fade active show" id="detail-tab">
              <div className="row">
                <div className="col-12">
                  <div className="">

                    <div className="row mb-2">
                      <div className="col-xl-10 col-lg-8 col-md-7">
                        <div className="d-flex justify-content-between">
                          <div className="form-group m-0 text-nowrap">
                            <span>Show</span>
                            <select className="form-control  text-center w-auto d-inline mx-2" onChange={handleChange}>
                              <option defaultValue={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <span>entries</span>
                          </div>


                          <form action="#" method="get">
                            <select onChange={(e) => setSearch(e.target.value)} name="search" className="btn btn-active">
                              {/* <option defaultValue={''}>Search By</option> */}
                              <option value={'symbol'}>COIN</option>
                            </select>
                          </form>


                        </div>
                      </div>


                      <div className="col-xl-2 col-lg-4 col-md-5 ">
                        <form action="#" method="get" >
                          <div className="card-tools m-0">
                            <div className="input-group input-group-sm" style={{ width: '100%' }}>
                              <input type="text" name="value" className="form-control " placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                              <div className="input-group-append">
                                <button type="submit" className="btn btn-default" onClick={submitForm}>
                                  <i className="fas fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="card-body table-responsive p-0 content-wrapper_1">
                      <table className="table text-nowrap">
                          <thead className="">
                            <tr>
                              <th>#</th>
                              <th onClick={() => applySorting('symbol', !sorting.ascending)} >COIN
                                <i className={sorting.key === '-symbol' || sorting.key === 'symbol' ? sorting.ascending === true && sorting.key === '-symbol' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th>ACTIONS</th>
                              <th onClick={() => applySorting('current_price', !sorting.ascending)} >LAST PRICE
                                <i className={sorting.key === '-current_price' || sorting.key === 'current_price' ? sorting.ascending === true && sorting.key === '-current_price' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('price_change_percentage_24h', !sorting.ascending)} >24H CHANGE
                                <i className={sorting.key === '-price_change_percentage_24h' || sorting.key === 'price_change_percentage_24h' ? sorting.ascending === true && sorting.key === '-price_change_percentage_24h' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('sparkline_in_7d', !sorting.ascending)} >MARKET
                                <i className={sorting.key === '-sparkline_in_7d' || sorting.key === 'sparkline_in_7d' ? sorting.ascending === true && sorting.key === '-sparkline_in_7d' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('created_at', !sorting.ascending)} >CREATED AT
                                <i className={sorting.key === '-created_at' || sorting.key === 'created_at' ? sorting.ascending === true && sorting.key === '-created_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('updated_at', !sorting.ascending)} >UPDATED AT
                                <i className={sorting.key === '-updated_at' || sorting.key === 'updated_at' ? sorting.ascending === true && sorting.key === '-updated_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading && <Spinner />}

                            {user?.data?.data?.map((value, i) => {
                              return (
                                <tr key={i}>
                                  <td>{(p++)}</td>
                                  <td>
                                    <div className="d-flex">
                                      <img src={value?.image} alt="logo" className="mr-3" style={{ width: '40px' }} /> 
                                      <span style={{textAlign:'left',fontWeight:'bold',marginRight:4}}>{(value?.symbol).toUpperCase()}</span>
                                      <br /> ({value?.name.toLowerCase()})

                                    </div>
                                  </td>
                                  <td>
                                    {Boolean(value?.elp_market_status) === false ?
                                      <form onSubmit={activateMarket}>
                                        <input name="user" type="hidden" value={value?._id}></input>
                                        <button type="submit" className="btn btn-success btn-sm my-2 px-3" value={'Activate'}>Activate</button><br />
                                      </form>
                                      : null}
                                    {Boolean(value?.elp_market_status) === true ?
                                      <form onSubmit={deactivateMarket}>
                                        <input name="user" type="hidden" value={value?._id}></input>
                                        <button type="submit" className="btn btn-danger btn-sm px-2" value={'deactivate'}>Deactivate</button>
                                      </form>
                                      : null}
                                  </td>
                                  <td>$ {value?.current_price}</td>
                                  <td>{(value?.price_change_percentage_24h) ? <div>{value?.price_change_percentage_24h.toFixed(2)}% {(value?.price_change_percentage_24h) > 0 ? <div className="text-success font-weight-bold d-inline-flex px-2">↑</div> : <div className="text-danger font-weight-bold d-inline-flex px-2"> ↓</div>}</div> : null} </td>
                                  <td>
                                    {(() => {
                                      if (value?.price_change_24h > 0) {
                                        return (
                                          <div className="sparkwidth"><Sparklines data={(value?.sparkline_in_7d[0]['price'] !== '') ? value?.sparkline_in_7d[0]['price'] : ''}>
                                            <SparklinesLine color="green" />
                                          </Sparklines></div>
                                        )
                                      } else {
                                        return (
                                          <div><Sparklines data={(value?.sparkline_in_7d[0]['price'] !== '') ? value?.sparkline_in_7d[0]['price'] : ''}>
                                            <SparklinesLine color="red" />
                                          </Sparklines></div>
                                        )
                                      }
                                    })()}
                                  </td>
                                  <td>{dateFormat(value?.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                  <td>{dateFormat(value.updated_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {records > perPage ?
                <div className="row mt-4">
                  <div className="col-12 text-right">
                    <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" >
                      Showing {currentPage > 1 ? currentPage * perPage - perPage + 1 : currentPage} to {currentPage * perPage} of {records} entries
                    </div>
                  </div>

                  <div className="col-12">
                    <ReactPaginate
                      pageCount={user?.total > 0 ? Math.ceil(user?.total / perPage) : 1}
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      previousLabel={"Previous"}
                      nextLabel={"NEXT"}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      renderOnZeroPageCount={1}
                    />
                  </div>
                </div>
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainMarket;