import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { API_URL } from "../../config/Config";
import Spinner from "../Spinner";
import { useNavigate, Link, useParams } from "react-router-dom";
import { get } from "../../config/apis";
import dateFormat from "dateformat";


function DepositHistory() {
  const {symbol} = useParams()

  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [user, setUser] = useState('');
  const [records, setrecords] = useState(0);
  const [search, setSearch] = useState('')
  const [value, setValue] = useState('')
  const [sorting, setSorting] = useState({ key: '', ascending: false })
  let [p, setP] = useState(' ')

  const handleFetch = async () => {
    setUser(null)
    setLoading(true);
    var passcon = '';
    if (value !== '' && value !== undefined) {
      passcon = `&search=${search}&value=${value?.toLocaleLowerCase()}`;
    }
    var sycSearch = "";
    if(symbol !== undefined){
      sycSearch = '&symbol='+symbol; 
    }

    const res = await get(`${API_URL}/income/deposit-real-details?sort=${sorting.key+sycSearch}&page=${currentPage}&limit=${perPage}${passcon}`,
      {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("Token"),
      }
    );
    if (res?.status === "success") {
      setUser(res);
      setrecords(res.total);
      setLoading(false);
      setP(res.page + 1)
    } else if (res?.status === "error") {
      localStorage.clear();
      navigate("/login");
    }
  }
  const handlePageChange = async (e) => {
    const newOffset = (e.selected + 1);
    setcurrentPage(newOffset);
  };

  useEffect(() => {
    handleFetch(); // eslint-disable-next-line
  }, [currentPage, perPage, search, value,symbol]);

  const submitForm = async (e) => {
    e.preventDefault();
    handleFetch();
  }

  const applySorting = (key, ascending) => {
    if (ascending) {
      setSorting({ key: '', ascending: false });
      setSorting({ key: '-' + key, ascending: ascending });
      handleFetch();
    } else {
      setSorting({ key: '', ascending: false });
      setSorting({ key: key, ascending: ascending });
      handleFetch();

    }
  }


  const handleChange = async (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
  }
  // const handleProceed = (e) => {
  //   navigate(("/elpform/:id", { id }));
  // };


  return (
    <div className="content-wrapper-new">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"> <h1>DEPOSIT HISTORY</h1> </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                <li className="breadcrumb-item active">Fund Deposits</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="card card-primary card-outline card-outline-tabs mb-11">
        <div className="card-body p-0">
          <div className="tab-content" id="custom-tabs-four-tabContent">
            <div className="tab-pane fade active show" id="detail-tab">
              <div className="row">
                <div className="col-12">
                  <div className="">

                    <div className="row mb-2">
                      <div className="col-xl-10 col-lg-8 col-md-7 ">
                        <div className="d-flex justify-content-between">
                          <div className="form-group m-0 text-nowrap">
                            <span>Show</span>
                            <select className="form-control  text-center w-auto d-inline" onChange={handleChange}>
                              <option defaultValue={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <span>entries</span>
                          </div>

                          <form action="#" method="get" >
                            <select onChange={(e) => setSearch(e.target.value)} name="search" className="btn btn-active">
                              {/* <option defaultValue={''}>Search By</option> */}
                              <option value={'username'}>USERNAME</option>
                              <option value={'symbol'}>SYMBOL</option>
                              <option value={'chain'}>CHAIN</option>
                              <option value={'from'}>FROM</option>
                              <option value={'to'}>TO</option>
                            </select>
                          </form>


                        </div>
                      </div>

                      <div className="col-xl-2 col-lg-4 col-md-5">
                        <form action="#" method="get" >

                          <div className="card-tools m-0">
                            <div className="input-group input-group-sm" style={{ width: '100%' }}>
                              <input type="text" name="value" className="form-control" placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                              <div className="input-group-append">
                                <button type="submit" className="btn btn-default" onClick={submitForm} >
                                  <i className="fas fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="card-body table-responsive p-0">
                      <table className="table text-nowrap">
                          <thead className="">
                            <tr>
                              <th>#</th>
                              <th onClick={() => applySorting('username', !sorting.ascending)} >USERNAME
                                <i className={sorting.key === '-username' || sorting.key === 'username' ? sorting.ascending === true && sorting.key === '-username' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('coin_id', !sorting.ascending)} >COIN ID
                                <i className={sorting.key === '-coin_id' || sorting.key === 'coin_id' ? sorting.ascending === true && sorting.key === '-coin_id' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('symbol', !sorting.ascending)} >COIN
                                <i className={sorting.key === '-symbol' || sorting.key === 'symbol' ? sorting.ascending === true && sorting.key === '-symbol' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('chain', !sorting.ascending)} >CHAIN
                                <i className={sorting.key === '-chain' || sorting.key === 'chain' ? sorting.ascending === true && sorting.key === '-chain' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('amount', !sorting.ascending)} >AMOUNT
                                <i className={sorting.key === '-amount' || sorting.key === 'amount' ? sorting.ascending === true && sorting.key === '-amount' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('current_price', !sorting.ascending)} >CURRENT PRICE
                                <i className={sorting.key === '-current_price' || sorting.key === 'current_price' ? sorting.ascending === true && sorting.key === '-current_price' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('main_amount', !sorting.ascending)} >TOTAL AMOUNT
                                <i className={sorting.key === '-main_amount' || sorting.key === 'main_amount' ? sorting.ascending === true && sorting.key === '-main_amount' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th>BLOCKCHAIN TRANSACTION</th>
                              {/* <th onClick={() => applySorting('currency', !sorting.ascending)} >CURRENCY
                                <i className={sorting.key === '-currency' || sorting.key === 'currency' ? sorting.ascending === true && sorting.key === '-currency' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th> */}
                              <th onClick={() => applySorting('to', !sorting.ascending)} >TO
                                <i className={sorting.key === '-to' || sorting.key === 'to' ? sorting.ascending === true && sorting.key === '-to' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('from', !sorting.ascending)} >FROM
                                <i className={sorting.key === '-from' || sorting.key === 'from' ? sorting.ascending === true && sorting.key === '-from' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('created_at', !sorting.ascending)} >CREATED AT
                                <i className={sorting.key === '-created_at' || sorting.key === 'created_at' ? sorting.ascending === true && sorting.key === '-created_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('updated_at', !sorting.ascending)} >UPDATED AT
                                <i className={sorting.key === '-updated_at' || sorting.key === 'updated_at' ? sorting.ascending === true && sorting.key === '-updated_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading && <Spinner />}

                            {user?.data?.data?.map((value, i) => {
                              return (
                                <tr key={i}>
                                  <td>{p++}</td>
                                  <td>{value.username}</td>
                                  <td>{value.coin_id === 'elp_market' ? 'GTC Market':value.coin_id}</td>
                                  <td>{value.symbol.toUpperCase()}</td>
                                  <td>{value.chain.toUpperCase()}</td>
                                  <td>{value.amount.toFixed(3)}</td>
                                  <td>{value.data.current_price.toFixed(3)}</td>
                                  <td>{(value.main_amount.toFixed(3))}</td>
                                  <td onClick={() => window.open(value.blockchain_transaction)}>{value.blockchain_transaction && <button className="btn btn-style">View</button>}</td>
                                  {/* <td>{value.data.currency}</td> */}
                                  <td>
                                    <div className="d-flex justify-content-between">
                                      {value.blockchain_scan === 'https://tronscan.org/' ? <a className="text-link" href={value.blockchain_scan + "#/address/" + value.to} target='_blank' rel="noreferrer">{value.to}</a> : <a className="text-link" href={value?.blockchain_scan + "address/" + value.to} target='_blank' rel="noreferrer">{value.to}</a>} {value.to && <span onClick={() => navigator.clipboard.writeText(value.to)}><Link className="icon-copy"><i className="fa-regular fa-copy" title="Copy to Address to Clipboard"></i></Link></span>}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-between">
                                      {value.blockchain_scan === 'https://tronscan.org/' ? <a className="text-link" href={value.blockchain_scan + "#/address/" + value.from} target='_blank' rel="noreferrer">{value.from}</a> : <a className="text-link" href={value?.blockchain_scan + "address/" + value.from} target='_blank' rel="noreferrer">{value.from}</a>} {value.from && <span onClick={() => navigator.clipboard.writeText(value.from)}><Link className="icon-copy"><i className="fa-regular fa-copy" title="Copy to Address to Clipboard"></i></Link></span>}
                                    </div>
                                  </td>
                                  <td>{dateFormat(value.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                  <td>{dateFormat(value.updated_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {records > perPage ?
                <div className="row mt-4">
                  <div className="col-12 text-right">
                    <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" >
                      Showing {currentPage > 1 ? currentPage * perPage - perPage + 1 : currentPage} to {currentPage * perPage} of {records} entries
                    </div>
                  </div>

                  <div className="col-12 ">
                    <ReactPaginate
                      pageCount={user?.total > 0 ? Math.ceil(user?.total / perPage) : 1}
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      previousLabel={"Previous"}
                      nextLabel={"NEXT"}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      renderOnZeroPageCount={1}
                    />
                  </div>
                </div>
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepositHistory;

