import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { get, post } from '../../config/apis';
import { API_URL } from '../../config/Config';
import toasted from '../../config/toast';

function TokenEditForm() {
    const { id } = useParams();
    const navigate = useNavigate()
    const [getImage, setGetImage] = useState('')
    const [getValue, setGetValue] = useState('')

    const getSingle = async () => {

        const response = await get(`${API_URL}/market/marketData/${id}`, {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("Token")
        })
        if (response) {
            setGetValue(response)
        } else {
            toasted.error('Error!')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const postdata = {
            id: id,
            name: e.target[0].value,
            symbol: e.target[1].value,
            image: getImage
        }

        const res = await post(`${API_URL}/market/edit-token`, postdata, {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("Token")
        })
        if (res.status === 'success') {
            navigate('/manualToken')
            toasted.success(res.message)
        } else {
            toasted.error(res.message)
            // navigate('/manualToken')
            // localStorage.clear()
            // navigate('/login')
        }

    }


    useEffect(() => {
        getSingle(); // eslint-disable-next-line
    }, []);


    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setGetImage(base64)
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <div className="content-wrapper-new">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6"> <h1>EDIT TOKEN</h1> </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                                <li className="breadcrumb-item active">Edit Token</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <div className="card card-primary card-outline card-outline-tabs mb-11">
                <div className="card-body p-0">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div className="tab-pane fade active show" id="detail-tab">
                            <div className="row jusify-content-center">
                                <div className="col-12">
                                    {/* <div className="card-header" style={{ backgroundColor: theme }}>
                                            <h3 className="card-title">CREATE TOKEN</h3>
                                        </div> */}
                                    <div className='row m-0'>
                                        <div className='col-md-6'>
                                            <div className="">

                                                <div className="card-body p-0 create-token">

                                                    <form action='#' onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    {/* <input type="hidden" value={id} /> */}
                                                                    <label>Change Coin Name</label>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={getValue?.name}
                                                                        className="form-control"
                                                                        placeholder="Coin Name"
                                                                        name="name"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Change Coin Symbol</label>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={getValue?.symbol}
                                                                        className="form-control"
                                                                        placeholder="Coin Symbol"
                                                                        name="symbol"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Change Token Image</label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        placeholder="choose file"
                                                                        onChange={(e) => { handleFileRead(e) }}
                                                                        name='image' />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <button type="submit" className="btn btn-style" value={'submit'}>Submit</button>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenEditForm
