import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post } from '../../config/apis';
import { API_URL } from '../../config/Config';
import toasted from '../../config/toast';

function CreateToken() {
    const navigate = useNavigate()
    const [getImage, setGetImage] = useState('')
    const [tokenName, setTokenName] = useState()
    const [tokenSymbol, setTokenSymbol] = useState()


    const handleSubmit = async (e) => {
        e.preventDefault();
        const postData = {
            token_name: tokenName,
            token_symbol: tokenSymbol,
            token_image: getImage
        }
        console.log(postData);
        const res = await post(`${API_URL}/market/create-token`, postData, {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("Token")
        })
        if (res.status === 'success') {
            toasted.success(res.message || 'Token Successffuly Created!')
            navigate('/mainmarket')
        } else {
            toasted.error(res.message || "error")
        }

    }

    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setGetImage(base64)
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <div className="content-wrapper-new">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6"> <h1>CREATE TOKEN</h1> </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                                <li className="breadcrumb-item active">create token</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <div className="card card-primary card-outline card-outline-tabs mb-11">
                <div className="card-body p-0">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div className="tab-pane fade active show" id="detail-tab">
                            <div className="row jusify-content-center">
                                <div className="col-12">
                                    <div className='row m-0'>
                                        <div className='col-md-6'>
                                            <div className="">
                                                <div className="card-body p-0 create-token">
                                                    <form action='#' onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Coin Name</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Coin Name"
                                                                        name="token_name"
                                                                        onChange={(e) => setTokenName(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Coin Symbol</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Coin Symbol"
                                                                        name="coin_symbol"
                                                                        onChange={(e) => setTokenSymbol(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Token Image</label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        placeholder="choose file"
                                                                        onChange={(e) => { handleFileRead(e) }}
                                                                        name='token_image' />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <button type="submit" className="btn btn-style" value={'submit'}>Submit</button>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateToken
