import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { post } from "../config/apis";
import { API_URL, logo, title } from "../config/Config";
import toasted from "../config/toast";


const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const auth = localStorage.getItem("user");
    if (auth) {
      navigate("/");
    } else {
      localStorage.clear()
    }
  })

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const res = await post(`${API_URL}/auth/login`, { email, password },
        {
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      )
      if (res.status === 'success') {
        localStorage.setItem("user", res.data.user);
        localStorage.setItem("Token", res.token);
        localStorage.setItem("Roles", JSON.stringify('Admin'));
        navigate("/");
        toasted.success(res.message)
      } else {
        toasted.error('Login Session time Out, Please Login Again!'||res.message)
      }
    } catch {
      toasted.error('Internal Server Error!')
    }
  }


  // const res = await fetch(`${API_URL}/auth/login`, {
  //   method: "POST",
  //   body: JSON.stringify({ email, password }),
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  // });
  // const Data = await res.json();

  // if (Data.status === 'success') {
  //   localStorage.setItem("user", JSON.stringify(Data.data.user));
  //   localStorage.setItem("Token", JSON.stringify(Data.token));
  //   localStorage.setItem("Roles", JSON.stringify('Admin'));
  //   navigate("/");
  //   toasted.success('Login success')
  // } else {
  //   toasted.error(Data.message)
  // }

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo"> <Link to="#"><b>Admin-Panal - </b>{title}</Link>
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <div className="d-flex justify-content-center login__picture">
              <img src={logo} className="px-5" alt={title} />
            </div>
            <p className="login-box-msg">Sign in to start your session</p>
            <form action="#" method="post">
              <div className="input-group mb-3">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="E-mail Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  className="form-control"
                  type="password"
                  name="name"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  id="myInput"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              {/* <input type="checkbox" onclick="myFunction()" />Show Password */}
              <div className="row">
                <div className="col-8">
                </div>
                <div className="col-12">
                  <button type="submit" onClick={submitForm} className="btn btn-style">Sign In</button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Login



