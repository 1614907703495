import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import { get } from '../../config/apis';
import { API_URL } from '../../config/Config';
import Spinner from '../Spinner';
import dateFormat from "dateformat";

function Activitylog() {

    const navigate = useNavigate();
    const [currentPage, setcurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [user, setUser] = useState('');
    const [records, setrecords] = useState(' ');
    const [search, setSearch] = useState('')
    const [value, setValue] = useState('')
    const [sorting, setSorting] = useState({ key: '', ascending: false })
    let [p, setP] = useState(' ')


    const handleFetch = async () => {
        setUser(null)
        setLoading(true);
        const res = await get(`${API_URL}/auth/logs?sort=${sorting.key}&page=${currentPage}&limit=${perPage}&search=${search}&value=${value.toLowerCase()}`,
            {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + localStorage.getItem('Token')
            }
        )
        if (res?.status === 'success') {
            setUser(res);
            setrecords(res.total);
            setLoading(false);
            setP(res.page + 1)
        } else if (res?.status === 'error') {
            localStorage.clear()
            navigate("/login");
        }
    };



    useEffect(() => {
        handleFetch(); // eslint-disable-next-line
    }, [currentPage, perPage, search, value]);


    const handlePageChange = async (e) => {
        const newOffset = (e.selected + 1);
        setcurrentPage(newOffset);
    };


    const submitForm = async (e) => {
        e.preventDefault();
        handleFetch();
    };
    const applySorting = (key, ascending) => {
        if (ascending) {
            setSorting({ key: '', ascending: false });
            setSorting({ key: '-' + key, ascending: ascending });
            handleFetch();
        } else {
            setSorting({ key: '', ascending: false });
            setSorting({ key: key, ascending: ascending });
            handleFetch();

        }
    }

    const handleChange = async (e) => {
        e.preventDefault();
        setPerPage(e.target.value);
    }






    return (
        <>
            <div className="content-wrapper-new" >
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6"> <h1>ACTIVITY LOGS</h1> </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                                    <li className="breadcrumb-item active">Activity Logs</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="card card-primary card-outline card-outline-tabs mb-11">
                    <div className="card-body p-0">
                        <div className="tab-content" id="custom-tabs-four-tabContent">
                            <div className="tab-pane fade active show" id="detail-tab">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="">

                                            <div className="row mb-2">
                                                <div className="col-xl-10 col-lg-8 col-md-7 ">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="form-group m-2 text-nowrap">
                                                            <span>Show</span>
                                                            <select className="form-control  text-center w-auto d-inline" onChange={handleChange}>
                                                                <option defaultValue={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                            <span>entries</span>
                                                        </div>
                                                        <form action="#" method="get" className="justify-content-end d-inline-flex align-items-center">
                                                            <select onChange={(e) => setSearch(e.target.value)} name="search" className="btn btn-active">
                                                                <option defaultValue={''}>Search By</option>
                                                                <option value={'ip_address'}>IP</option>


                                                            </select>
                                                        </form>
                                                    </div>



                                                </div>

                                                <div className="col-xl-2 col-lg-4 col-md-5">
                                                    <form action="#" method="get" className="">

                                                        <div className="card-tools ml-0 mr-0">
                                                            <div className="input-group input-group-sm" style={{ width: '100%' }}>
                                                                <input type="text" name="value" className="form-control float-right" placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                                                                <div className="input-group-append">
                                                                    <button type="submit" className="btn btn-default" onClick={submitForm}>
                                                                        <i className="fas fa-search" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>


                                            <div className="card-body table-responsive p-0 content-wrapper_1">
                                                <table className="table text-nowrap">
                                                    
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th onClick={() => applySorting('created_at', !sorting.ascending)}>DATE
                                                            <i className={sorting.key === '-created_at' || sorting.key === 'created_at' ? sorting.ascending === true && sorting.key === '-created_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th>IP</th>
                                                            <th>SOURCE</th>
                                                            <th>ACTIVITY</th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {loading && <Spinner />}
                                                        {user?.data?.data?.map((value, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{(p++)}</td>
                                                                    <td>{dateFormat(value.created_at, "dd-mm-yyyy TT hh:mm:ss")}</td>
                                                                    <td>{value.ip_address}</td>
                                                                    <td>{value.source}</td>
                                                                    <td>{value.description}</td>

                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                   
                                                </table>
                                            </div>
                                            {records > perPage ?
                                                <div className="row mt-4">
                                                    <div className="col-12 text-right">
                                                        <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" >
                                                            Showing {currentPage > 1 ? currentPage * perPage - perPage + 1 : currentPage} to {currentPage * perPage} of {records} entries
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <ReactPaginate
                                                            pageCount={user?.total > 0 ? Math.ceil(user?.total / perPage) : 1}
                                                            onPageChange={handlePageChange}
                                                            pageRangeDisplayed={1}
                                                            marginPagesDisplayed={2}
                                                            previousLabel={"PRV"}
                                                            nextLabel={"NEXT"}
                                                            containerClassName={"pagination justify-content-center"}
                                                            pageClassName={"page-item"}
                                                            pageLinkClassName={"page-link"}
                                                            previousClassName={"page-item"}
                                                            previousLinkClassName={"page-link"}
                                                            nextClassName={"page-item"}
                                                            nextLinkClassName={"page-link"}
                                                            breakClassName={"page-item"}
                                                            breakLinkClassName={"page-link"}
                                                            activeClassName={"active"}
                                                            renderOnZeroPageCount={1}
                                                        />
                                                    </div>

                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Activitylog