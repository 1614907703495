import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import { get } from '../../config/apis';
import { API_URL } from '../../config/Config';
import Spinner from '../Spinner';
import dateFormat from "dateformat";

function TradeOrders() {

    const navigate = useNavigate();
    const [currentPage, setcurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [user, setUser] = useState('');
    const [records, setrecords] = useState(' ');
    const [search, setSearch] = useState('')
    const [value, setValue] = useState('')
    const [sorting, setSorting] = useState({ key: '', ascending: false })
    let [p, setP] = useState(' ')


    const handleFetch = async () => {
        setUser(null)
        setLoading(true);
        var passcon = '';
    if (value !== '' && value !== undefined) {
      passcon = `&search=${search}&value=${value?.toLocaleLowerCase()}`;
    }
        const res = await get(`${API_URL}/market/trade-history?sort=${sorting.key}&page=${currentPage}&limit=${perPage}${passcon}`,
            {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + localStorage.getItem('Token')
            }
        )
        if (res?.status === 'success') {
            setUser(res);
            setrecords(res.total);
            setLoading(false);
            setP(res.page + 1)
        } else if (res?.status === 'error') {
            localStorage.clear()
            navigate("/login");
        }
    };



    useEffect(() => {
        handleFetch(); // eslint-disable-next-line
    }, [currentPage, perPage, search, value]);


    const handlePageChange = async (e) => {
        const newOffset = (e.selected + 1);
        setcurrentPage(newOffset);
    };


    const submitForm = async (e) => {
        e.preventDefault();
        handleFetch();
    };
    const applySorting = (key, ascending) => {
        if (ascending) {
            setSorting({ key: '', ascending: false });
            setSorting({ key: '-' + key, ascending: ascending });
            handleFetch();
        } else {
            setSorting({ key: '', ascending: false });
            setSorting({ key: key, ascending: ascending });
            handleFetch();

        }
    }

    const handleChange = async (e) => {
        e.preventDefault();
        setPerPage(e.target.value);
    }






    return (
        <>
            <div className="content-wrapper-new" >
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6"> <h1>TRADE ORDERS</h1> </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                                    <li className="breadcrumb-item active">Trade Orders</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="card card-primary card-outline card-outline-tabs mb-11">
                    <div className="card-body p-0">
                        <div className="tab-content" id="custom-tabs-four-tabContent">
                            <div className="tab-pane fade active show" id="detail-tab">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="">

                                            <div className="row mb-2">
                                                <div className="col-xl-10 col-lg-8 col-md-7 ">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="form-group m-2 text-nowrap">
                                                            <span>Show</span>
                                                            <select className="form-control  text-center w-auto d-inline" onChange={handleChange}>
                                                                <option defaultValue={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                            <span>entries</span>
                                                        </div>
                                                        <form action="#" method="get" className="justify-content-end d-inline-flex align-items-center">
                                                            <select onChange={(e) => setSearch(e.target.value)} name="search" className="btn btn-active">
                                                                {/* <option defaultValue={''}>Search By</option> */}
                                                                <option value={'username'}>USERNAME</option>


                                                            </select>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="col-xl-2 col-lg-4 col-md-5">
                                                    <form action="#" method="get" className="">

                                                        <div className="card-tools ml-0 mr-0">
                                                            <div className="input-group input-group-sm" style={{ width: '100%' }}>
                                                                <input type="text" name="value" className="form-control float-right" placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                                                                <div className="input-group-append">
                                                                    <button type="submit" className="btn btn-default" onClick={submitForm}>
                                                                        <i className="fas fa-search" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>


                                            <div className="card-body table-responsive p-0 content-wrapper_1">
                                                <table className="table text-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th onClick={() => applySorting('username', !sorting.ascending)}>USERNAME
                                                                <i className={sorting.key === '-username' || sorting.key === 'username' ? sorting.ascending === true && sorting.key === '-username' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>

                                                            <th onClick={() => applySorting('market_trade_type', !sorting.ascending)}>MARKET TRADE TYPE
                                                                <i className={sorting.key === '-market_trade_type' || sorting.key === 'market_trade_type' ? sorting.ascending === true && sorting.key === '-market_trade_type' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>

                                                            <th onClick={() => applySorting('buy_currency', !sorting.ascending)}>BUY CURRENCY
                                                                <i className={sorting.key === '-buy_currency' || sorting.key === 'buy_currency' ? sorting.ascending === true && sorting.key === '-buy_currency' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('sell_currency', !sorting.ascending)}>SELL CURRENCY
                                                                <i className={sorting.key === '-sell_currency' || sorting.key === 'sell_currency' ? sorting.ascending === true && sorting.key === '-sell_currency' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('coin', !sorting.ascending)}>AMMOUNT
                                                                <i className={sorting.key === '-coin' || sorting.key === 'coin' ? sorting.ascending === true && sorting.key === '-coin' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('receive_coin', !sorting.ascending)}>RECEIVE AMMOUNT
                                                                <i className={sorting.key === '-receive_coin' || sorting.key === 'receive_coin' ? sorting.ascending === true && sorting.key === '-receive_coin' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('pending_coin', !sorting.ascending)}>PENDING AMMOUNT
                                                                <i className={sorting.key === '-pending_coin' || sorting.key === 'pending_coin' ? sorting.ascending === true && sorting.key === '-amount2' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('coin_price', !sorting.ascending)}>COIN PRICE
                                                                <i className={sorting.key === '-coin_price' || sorting.key === 'coin_price' ? sorting.ascending === true && sorting.key === '-coin_price' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('order_id', !sorting.ascending)}>ORDER ID
                                                                <i className={sorting.key === '-order_id' || sorting.key === 'order_id' ? sorting.ascending === true && sorting.key === '-order_id' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('order_status', !sorting.ascending)}>ORDER STATUS
                                                                <i className={sorting.key === '-order_status' || sorting.key === 'order_status' ? sorting.ascending === true && sorting.key === '-order_status' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th>ORDER TYPE</th>
                                                            <th onClick={() => applySorting('trade_on', !sorting.ascending)}>TRADE ON
                                                                <i className={sorting.key === '-trade_on' || sorting.key === 'trade_on' ? sorting.ascending === true && sorting.key === '-trade_on' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('trade_type', !sorting.ascending)}>TRADE TYPE
                                                                <i className={sorting.key === '-trade_type' || sorting.key === 'trade_type' ? sorting.ascending === true && sorting.key === '-trade_type' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th>UPDATED AT</th>



                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {loading && <Spinner />}
                                                        {user?.data?.data?.map((value, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{(p++)}</td>

                                                                    <td>{value.username}</td>
                                                                    <td className={value.market_trade_type === 'buy' ? 'text-success font-weight-bold' : 'text-danger font-weight-bold'}>{value.market_trade_type.toUpperCase()}</td>
                                                                    <td>{value.buy_currency.toUpperCase()} ({value.coin_id})</td>
                                                                    <td>{value.sell_currency.toUpperCase()}</td>
                                                                    <td>{value.coin}</td>                                                   {/* AMMOUNT */}
                                                                    <td>{ value.receive_coin}</td>     {/* RECEIVE AMMOUNT */}
                                                                    <td>{value.receive_coin === 0 ? value.coin : value.pending_coin}</td>             {/* PENDING AMMOUNT */}
                                                                    <td>{value.coin_price}</td>
                                                                    <td>{value.order_id}</td>
                                                                    <td className={(value.order_status === 'completed') ? 'text-success font-weight-bold' : ((value.order_status === 'pending') ? 'text-warning font-weight-bold' : 'text-danger font-weight-bold')}>{value.order_status.toUpperCase()}</td>
                                                                    <td><span className='text-success font-weight-bold'>BUY </span> {value.order_type.buy.toUpperCase()} <div><span className='text-danger font-weight-bold'>SELL </span>{value.order_type.sell.toUpperCase()}</div></td>
                                                                    <td>{value.trade_on ? value.trade_on.toUpperCase() : null}</td>
                                                                    <td>{value.trade_type ? value.trade_type.toUpperCase() : null}</td>
                                                                    <td>{dateFormat(value.updatedAt, "dd-mm-yyyy TT hh:mm:ss")}</td>
                                                                    

                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                    
                                                </table>
                                            </div>
                                            {records > perPage ?
                                                <div className="row mt-4">
                                                    <div className="col-12 text-right">
                                                        <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" >
                                                            Showing {currentPage > 1 ? currentPage * perPage - perPage + 1 : currentPage} to {currentPage * perPage} of {records} entries
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <ReactPaginate
                                                            pageCount={user?.total > 0 ? Math.ceil(user?.total / perPage) : 1}
                                                            onPageChange={handlePageChange}
                                                            pageRangeDisplayed={1}
                                                            marginPagesDisplayed={2}
                                                            previousLabel={"PRV"}
                                                            nextLabel={"NEXT"}
                                                            containerClassName={"pagination justify-content-center"}
                                                            pageClassName={"page-item"}
                                                            pageLinkClassName={"page-link"}
                                                            previousClassName={"page-item"}
                                                            previousLinkClassName={"page-link"}
                                                            nextClassName={"page-item"}
                                                            nextLinkClassName={"page-link"}
                                                            breakClassName={"page-item"}
                                                            breakLinkClassName={"page-link"}
                                                            activeClassName={"active"}
                                                            renderOnZeroPageCount={1}
                                                        />
                                                    </div>

                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default TradeOrders